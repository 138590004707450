//APP
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

//Layout
import Header from "./layout/Header";
import Navbar from "./layout/Navbar";

//Pages
import Home from "./pages/Home";
import IniciativaPopular from "./pages/IniciativaPopular";
import ConsultaPopular from "./pages/ConsultaPopular";
import Plebiscito from "./pages/Plebiscito";
import Referendum from "./pages/Referendum";
import VozCiudadana from "./pages/VozCiudadana";
import Convenios from "./pages/Convenios";
import Eventos from "./pages/Eventos";
import Infografias from "./pages/Infografias";
import Tripticos from "./pages/Tripticos";
import SitiosInteres from "./pages/SitiosInteres";
import Encivica from "./pages/Encivica";

function App() {
  return (
    <Router>
      <Header />
      <Navbar />
      <div className="App__container">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/IniciativaPopular" element={<IniciativaPopular />} />
          <Route path="/ConsultaPopular" element={<ConsultaPopular />} />
          <Route path="/Plebiscito" element={<Plebiscito />} />
          <Route path="/Referendum" element={<Referendum />} />
          <Route path="/VozCiudadana" element={<VozCiudadana />} />
          <Route path="/Convenios" element={<Convenios />} />
          <Route path="/Eventos" element={<Eventos />} />
          <Route path="/Infografias" element={<Infografias />} />
          <Route path="/Tripticos" element={<Tripticos />} />
          <Route path="/SitiosInteres" element={<SitiosInteres />} />
          <Route path="/Encivica" element={<Encivica />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
