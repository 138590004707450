import React, { Component } from "react";

export default class Tripticos extends Component {
  render() {

  return <div>
    <h1 className="text-center mb-4 mt-3">Estrategia Nacional de Educación Cívica 2024-2026 </h1>
    <object data="https://itetlax.org.mx/participacion-ciudadana/assets/pdf/encivica20242026.pdf"
      type="application/pdf" width="100%" height="800px">
    </object>
    
    <center>
      <video autoplay src="https://itetlax.org.mx/participacion-ciudadana/assets/vid/1.mp4" width="640" height="360" controls></video>
    </center>
  </div>;
  }
}
